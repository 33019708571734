import React from 'react'
import NavBar from '../../Navbar/Navbar'
import Completeprojecr from './Completeprojecr'
import Contactcomponent from '../../Contact/Contactcomponent'
import '../Upcomming/Upcomming.css';


function Cp() {
  return (
    <div>
    <div><NavBar/></div>
    <div className='navmargin'><Completeprojecr/></div>
    <div><Contactcomponent/></div>

</div>
  )
}

export default Cp