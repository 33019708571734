import React from 'react'
import NavBar from '../Navbar/Navbar'
import Contactcomponent from './Contactcomponent'
import '../Project/Upcomming/Upcomming.css';

function Contact() {
    return (
        <div>
            <div><NavBar/></div>
            <div className='navmargin'><Contactcomponent/></div>
        </div>
    )
}

export default Contact