import React from 'react';
import sapatarshi from '../../../img/Saptarshi.jpeg'

import './Booking.css'
import Booking from './Booking';

function Booksaptarshi() {
  return (
    <div className="wrapper-cp">
      <div className='onproject-title'><h1>Saptarshi</h1></div>
      <Booking
        id="card1"
        frontImage={sapatarshi}
        bed={2}
        hall={1}
        kitchen={1}
        bedtoilet={2}
        common_toilet ={1}

        description={[
          'Gulmohor Colony, Near Chitale Express, Sangli',
          'गुलमोहोर कॉलनी ,चितळे एक्सप्रेस जवळ ,सांगली '
        ]}
        location="सप्तर्षी"
      />
      <div className='text-center font-bold text-xl text-blue-800 mb-8'>Gulmohor Colony, Near Chitale Express, Sangli</div>
    </div>
  )
}

export default Booksaptarshi