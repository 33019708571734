import './App.css';
import Home from './Component/Home/Home';
import About from './Component/About/About';
import Contact from './Component/Contact/Contact';
import Upcomming from './Component/Project/Upcomming/Upcomming';
import Completeprojecr from './Component/Project/Complete/Completeprojecr';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Founders from './Component/About/Founders';
import Up from './Component/Project/Upcomming/Up';
import Cp from './Component/Project/Complete/Cp';

function App() {
  return (

      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/up' element={<Up />} />
            <Route path='/cp' element={<Cp />} />
            <Route path="/founder" element={<Founders />} />

          </Routes>
        </BrowserRouter>
      </div>
    );
  }
  
  export default App;
  