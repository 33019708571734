import React, { useState } from 'react';

import img2 from '../../img/Navlogo copy.png';
import './NavBar.css'
import { Link } from 'react-router-dom';

function NavBar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };


  return (

    <nav className={`mainNav ${menuOpen ? 'open' : ''}`}>
      <div className="mainNav__logo">
        <img src={img2} alt="Logo" />
      </div>
      <div className="mainNav__links">
        <a href="" className="mainNav__link">
          <Link to="/" >Home</Link>
        </a>
        <a href="" className="mainNav__link">
          <Link to="/about" >About Us</Link>
        </a>
        <a href="" className="mainNav__link">
          <Link to="/up" >Ongoing Projects</Link>
        </a>
        <a href="" className="mainNav__link">
          <Link to="/cp" >Completed Projects</Link>
        </a>
        <a href="" className="mainNav__link">
          <Link to="/contact" >Contact Us</Link>
        </a>

      </div>
      <div className="mainNav__icon" onClick={toggleMenu}>
        {/* Replace the SVG icon with your own hamburger menu icon */}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <g data-name="Layer 2" fill="#9197AE">
            <g data-name="menu-2">
              <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0" />
              <circle cx="4" cy="12" r="1" />
              <rect x="7" y="11" width="14" height="2" rx=".94" ry=".94" />
              <rect x="3" y="16" width="18" height="2" rx=".94" ry=".94" />
              <rect x="3" y="6" width="18" height="2" rx=".94" ry=".94" />
            </g>
          </g>
        </svg>
      </div>
    </nav>
  )
}

export default NavBar