import React, { useState } from 'react';
import './Upcomming.css';
import Booksaptarshi from './Booksaptarshi';
import Bookshataraka from './Bookshataraka';
import { FaDownload } from "react-icons/fa";
import Saptarshi from "../../../img/SaptarshiPDF.jpg";
import Shatataraka from "../../../img/ShatatarakaPDF.jpg";

function Upcomming() {
    const [loadingSaptarshi, setLoadingSaptarshi] = useState(false);
    const [loadingShataraka, setLoadingShataraka] = useState(false);

    const handleDownloadImage = (imageFileName, setLoading, imageUrl) => {
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = imageFileName;
        document.body.appendChild(link);
        
        link.click();
        
        document.body.removeChild(link);

        setLoading(false);
    };

    const handleClickSaptarshi = () => {
        setLoadingSaptarshi(true);
        setTimeout(() => {
            handleDownloadImage('सप्तर्षी.jpg', setLoadingSaptarshi, Saptarshi);
        }, 1000);
    }

    const handleClickShataraka = () => {
        setLoadingShataraka(true);
        setTimeout(() => {
            handleDownloadImage('शततारका.jpg', setLoadingShataraka, Shatataraka);
        }, 1000); 
    }
    
    return (
        <div className="containerp">
            <div className="contentp1">
                <h1 id='contentpid'>Upcoming Project</h1>
                <Booksaptarshi />
                <div className='button-pdf' onClick={handleClickSaptarshi}>
                    <button className={`btn ${loadingSaptarshi ? 'loading' : ''}`} >
                        <i className="bx bx-send"><FaDownload /></i>
                        <span className="text" >
                        Download Floor Plan                        </span>
                        <span className="loading-animate"></span>
                    </button>
                </div>
            </div>
            <div className="contentp1">
                <h1 id='contentpid'>Ongoing Project</h1>
                <Bookshataraka />
                <div className='button-pdf' onClick={handleClickShataraka}>
                    <button className={`btn ${loadingShataraka ? 'loading' : ''}`} >
                        <i className="bx bx-send"><FaDownload /></i>
                        <span className="text" >
                        Download Floor Plan                        </span>
                        <span className="loading-animate"></span>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Upcomming;
