import React from 'react'
import NavBar from '../Navbar/Navbar'
import Homecomponent from './Homecomponent'
import Upcomming from '../Project/Upcomming/Upcomming'
import Completeprojecr from '../Project/Complete/Completeprojecr'
import Contactcomponent from '../Contact/Contactcomponent'
import Aboutcomponent from '../About/Aboutcomponent'

function Home() {
  return (
    <div>
        <div><NavBar/></div>
        <div><Homecomponent/></div>
        <div><Aboutcomponent/></div>
        <div><Upcomming/></div>
        <div><Completeprojecr/></div>
        <div><Contactcomponent/></div>

    </div>
  )
}

export default Home