import React, { useState } from 'react';
import { FaFacebookF, FaInstagram, FaPhoneAlt, FaWhatsapp } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { Link } from 'react-router-dom';

function Contactcomponent() {
    const [formData, setFormData] = useState({
        name: '',
        city: '',
        email: '',
        phone: '',
        selectedOption: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Construct the WhatsApp message
        const whatsappMessage = `Name: ${formData.name}%0aCity: ${formData.city}%0aEmail: ${formData.email}%0aPhone: ${formData.phone}%0aInterested in Project: ${formData.selectedOption}%0aMessage: ${formData.message}`;

        // Form a WhatsApp link with the message
        const whatsappLink = `https://wa.me/9860899900?text=${whatsappMessage}`;

        // Open the WhatsApp link in a new tab
        window.open(whatsappLink, '_blank');
    };
    return (
        <div className=" pt-96 md:pt-0">
            <div class="flex justify-center items-center  w-screen h-screen bg-white">
                <div class="container mx-auto my-4 px-4 lg:px-20">

                    <div class="w-full p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
                        <div class="flex">
                            <h1 class="font-bold uppercase text-5xl">Enquire Now</h1>
                        </div>
                        <div class="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            <input
                                className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                type="text"
                                placeholder="Your Name*"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                            <input class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                type="text"
                                placeholder="Your City*"
                                name="city"
                                value={formData.city}
                                onChange={handleChange} />
                            <input class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                type="email"
                                placeholder="Your Email*"
                                name="email"
                                value={formData.email}
                                onChange={handleChange} />
                            <input class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                type="tel"
                                placeholder="Your Mobile Number*"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange} />
                        </div>
                        <div class="my-4">

                            <select
                                class="w-full mb-4 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                name="selectedOption"
                                value={formData.selectedOption}
                                onChange={handleChange}
                            >
                                <option value="" disabled>Select Option</option>
                                <option value="Shatataraka">Shatataraka</option>
                                <option value="Saptarshi">Saptarshi</option>
                            </select>
                            <textarea
                                placeholder="Message*"
                                class="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                            ></textarea>
                        </div>

                        <div class="my-2 w-1/2 lg:w-1/4">
                            <button class="uppercase text-sm font-bold tracking-wide bg-blue-900 text-gray-100 p-3 rounded-lg w-full 
                      focus:outline-none focus:shadow-outline"
                                type="submit"
                                onClick={handleSubmit}
                            >
                                Send Message
                            </button>
                        </div>
                    </div>

                    <div class="w-full lg:-mt-96 lg:w-2/6 px-8 py-12 ml-auto bg-blue-900 rounded-2xl mb-96 md:mb-0">
                        <div class="flex flex-col text-white">
                            <h1 class="font-bold uppercase text-4xl my-4">Contact Us</h1>


                            <div class="flex my-4 w-3/3 lg:w-2/2">
                                <div class="flex flex-col">
                                    <h2 class="text-2xl text-left">Office Address</h2>
                                    <p class="text-gray-100 text-left">Sagar Sumant Kulkarni, Madhumalti, Overseers Colony, Near Shivshakti Mandal, Sangli - 416416</p>
                                </div>
                            </div>

                            <div class="flex my-4 w-2/3 lg:w-1/2">
                                <div class="flex flex-col">
                                    <i class="fas fa-phone-alt pt-2 pr-2" />
                                </div>
                                <div class="flex flex-col">
                                    <h2 class="text-2xl text-left" >Call Us</h2>
                                    <p class="text-gray-100 ">Tel: 9860899900</p>
                                </div>
                            </div>
                            <div class="flex my-4 w-2/3 lg:w-1/2">
                                {/* Phone Call */}
                                <div class="rounded-full bg-white h-12 w-12 flex items-center justify-center mx-1">
                                    <a href="tel:9860899900">
                                        <FaPhoneAlt className="text-blue-900 text-xl" />
                                    </a>
                                </div>

                                {/* WhatsApp */}
                                <div class="rounded-full bg-white h-12 w-12 flex items-center justify-center mx-1 ">
                                    <a href="https://wa.me/9860899900" target="_blank" rel="noreferrer">
                                        <FaWhatsapp className="text-green-500 text-xl " />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="bg-white ">
                <div class="max-w-screen-xl px-4 py-12 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
                    <nav class="flex flex-wrap justify-center -mx-5 -my-2">
                        <div class="px-5 py-2">
                            <Link to='/about'>  <a href="#" class="text-base leading-6 text-gray-500 hover:text-gray-900">
                                About US
                            </a></Link>
                        </div>
                        <div class="px-5 py-2">
                            <Link to='/cp'>  <a href="#" class="text-base leading-6 text-gray-500 hover:text-gray-900">
                                Completed Projects
                            </a></Link>
                        </div>
                        <div class="px-5 py-2">
                            <Link to='/up'>  <a href="#" class="text-base leading-6 text-gray-500 hover:text-gray-900">
                                OnGoing Projects
                            </a></Link>
                        </div>

                    </nav>
                    <div class="flex justify-center mt-8 space-x-6">
                        <a href="https://www.facebook.com/sagarsumantkulkarni" className="text-gray-600 hover:text-blue-500">
                            <span className="sr-only">Facebook</span>
                            <FaFacebookF className="w-6 h-6" />
                        </a>
                        <a href="https://www.instagram.com/" className="text-gray-600 hover:text-pink-500">
                            <span className="sr-only">Instagram</span>
                            <FaInstagram className="w-6 h-6" />
                        </a>
                        <a href="https://wa.me/9860899900" target="_blank" rel="noreferrer" className="text-gray-600 hover:text-green-500">
                            <span className="sr-only">WhatsApp</span>
                            <FaWhatsapp className="w-6 h-6" />
                        </a>
                        <a href="mailto:sagarkulkarni53@gmail.com" className="text-gray-600 hover:text-red-500">
                            <span className="sr-only">Email</span>
                            <MdEmail className="w-6 h-6" />
                        </a>
                    </div>

                    <p class="mt-8 md:mt-0 text-base leading-6 text-center text-gray-400">
                        &copy; 2024 Aditya Developers and Builders. All Rights Reserved To Business Mantra.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Contactcomponent