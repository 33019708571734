import React from 'react'
import NavBar from '../../Navbar/Navbar'
import Upcomming from './Upcomming'
import Contactcomponent from '../../Contact/Contactcomponent'
import './Upcomming.css';

function Up() {
    return (
        <div>
            <div><NavBar/></div>
            <div className='navmargin'><Upcomming/></div>
            <div><Contactcomponent/></div>

        </div>
    )
}

export default Up