import React from 'react'
import './Homecomponent.css'; 
import img1 from '../../img/WhatsApp Image 2023-11-09 at 11.32.07 AM.jpeg';
import { Link } from 'react-router-dom';
import NavBar from '../Navbar/Navbar';

function Homecomponent() {

 
  return (
    <div>
      
         <NavBar/>
            <div class="mainHeading">
                <div class="mainHeading__content">
                    <div class="mainHeading__text">
                        <p class="mainHeading__preTitle">आदित्य </p>
                        <h2 class="mainHeading__title"> Developers and Builders</h2>
                        <p class="mainHeading__description">
                        “ जिथे वास्तु  म्हणते तथास्तु ”
                        </p>
                        <button class="cta"><Link to="/contact">Get Enquiry</Link></button>
                    </div>

                    <figure class="mainHeading__image">
                        <img
                            src={img1}
                            alt=""
                        />
                    </figure>
                </div>

            </div>
        </div>
    )
}

export default Homecomponent