import React from 'react'
import './AboutCard.css'
import { FaAward,FaCity,FaHome,FaMapSigns    } from "react-icons/fa";

function AboutCard() {
    return (
        <div class="containerab">
            <div class="cardab">
                <div class="boxab">
                    <div class="contentab">
                        <h3>10+ Commercial Projects</h3>
                        <p>< FaCity /></p>
                    </div>
                </div>
            </div>

            <div class="cardab">
                <div class="boxab">
                    <div class="contentab">
                        {/* <h2>02</h2> */}
                        <h3>Decades of Trust</h3>
                        <p>< FaAward /></p>
                    </div>
                </div>
            </div>

            <div class="cardab">
                <div class="boxab">
                    <div class="contentab">
                        {/* <h2>03</h2> */}
                        <h3>10+ Residential Projects</h3>
                        {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, totam velit? Iure nemo labore inventore?</p> */}
                        <p>< FaHome /></p>
                    </div>
                </div>
            </div>
            <div class="cardab">
                <div class="boxab">
                    <div class="contentab">
                        {/* <h2>04</h2> */}
                        <h3> Million Sq. Ft. developed</h3>
                        {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, totam velit? Iure nemo labore inventore?</p> */}
                        <p>< FaMapSigns  /></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutCard