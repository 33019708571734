import React from 'react';
import shatarka from '../../../img/Shatatarka.jpeg'

import './Booking.css'
import Booking from './Booking';

function Bookshataraka() {
  return (
    <div className="wrapper-cp">

      <div className='onproject-title'><h1>Shatataraka</h1></div>

      {/* Card 2 */}
      <Booking
        id="card2"
        frontImage={shatarka}
        bed={2}
        hall={1}
        kitchen={1}
        bedtoilet={2}
        common_toilet ={1}
        description={[
          'Near Shivshakti Vyayam Mandal, Overseers Colony, South Shivaji Nagar, Sangli',
          'शिवशक्ती व्यायाम मंडळाजवळ, ओव्हरसर्स कॉलनी, दक्षिण शिवाजी नगर, सांगली'
        ]}
        location="शततारका "
      />
      <div className='text-center font-bold text-xl text-blue-800 mb-6'>Near Shivshakti Vyayam Mandal,<br/> Overseers Colony, South Shivaji Nagar, Sangli</div>
    </div>
  );
};

export default Bookshataraka;
