import React from 'react'
import './Aboutcomponent.css'

import { Link } from 'react-router-dom';
import AboutCard from './AboutCard';
import Owner from './Owner';


function Aboutcomponent() {
    return (
        <div>
            <div class="contain">

                <h1>AN ARRIVAL EXPERIENCE THAT UPLIFTS YOUR SPIRITS: ADITYA DEVELOPERS AND BUILDERS</h1>
<Owner/>
                <p>Aditya Developers and Builders Sangli was founded by Sumant Madhav Kulkarni and Mohini Sumant Kulkarni, two pioneers in the construction and real estate sector. Their combined vision and leadership, along with the dynamic leadership of Sagar Sumant Kulkarni, have established the company as a trusted name in the industry.</p>
                <AboutCard/>
            </div>
        </div>
    )
}

export default Aboutcomponent